<template>
  <div :class="classes">
    <div class="radio styled">
      <input :id="getId" v-model="optionSelected" :name="name" type="radio" :value="value" :disabled="disabled" class="form-control" @change="$emit('change', optionSelected)">
      <label v-if="label" :for="getId">{{ label }}</label>
    </div>
  </div>
</template>

<script>
import BaseInput from './BaseInput';

export default {
  extends: BaseInput,
  props: {
    selected: {
      type: null,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      optionSelected: this.selected
    };
  },
  watch: {
    selected() {
      this.optionSelected = this.selected;
    }
  }
};
</script>
