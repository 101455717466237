var _ = require('underscore');
var tectoastr = require('./tectoastr');
var PusherClass = require('pusher-js');
import { emit } from '@/Library/LocalEventBus';

if (typeof pusherOptions !== 'undefined') {
  let options = pusherOptions.clarify();

  var pusher = new PusherClass(options.appKey, options.options);

  var tokenExists = token =>
    typeof token !== 'undefined' && $('.' + token).length;

  var refreshIfExists = function(token) {
    if (tokenExists(token)) {
      var url = window.location.pathname + window.location.search;
      $.pjax({ url: url, container: '#pjaxContainer', method: 'GET' });
    }
  };

  var hideIfExists = function(hideElement, hideIfExists) {
    if (tokenExists(hideElement) && tokenExists(hideIfExists)) {
      $('.'+ hideElement).fadeOut();
    }
  };

  _.each(options.channels, function(channelName) {
    var channel = pusher.subscribe(channelName);
    channel.bind('message.simple', function(data) {
      tectoastr.notify(data.type, data.message);
      // Full pjax refresh
      if (data.refreshIfExists) {
        refreshIfExists(data.refreshIfExists);
      }
      // Hide element
      if (data.hideElement && data.hideIfExists) {
        hideIfExists(data.hideElement, data.hideIfExists);
      }
    });

    channel.bind('account.copy.completed', function() {
      emit('account.copy.completed');
    });

    channel.bind('account.copy.failed', function(data) {
      emit('account.copy.failed', [data.message, data.errors || []]);
    });
  });
}
