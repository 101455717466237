<script>
/**
 * The ColourJuggler component.
 *
 * Juggles brand colours, exposes the 'currentColour' computed property.
 */
export default {
  data() {
    return {
      palette: {
        plum: '#8b1360',
        royal: '#3d3dc4',
        teal: '#00c0eb',
        green: '#1da834',
        orange: '#f7810b',
        fuchsia: '#df165c'
      },
      colour: null,
      delay: 5000,
      interval: null
    };
  },
  computed: {
    colours() {
      return Object.keys(this.palette);
    },
    currentColour() {
      return this.palette[this.colour];
    }
  },
  mounted() {
    let index = Math.floor(Math.random() * this.colours.length);

    const juggleColours = () => {
      if (index == this.colours.length) {
        index = 0;
      }

      this.colour = this.colours[++index];
    };

    this.interval = window.setInterval(juggleColours, this.delay);

    juggleColours();
  },
  destroyed() {
    window.clearInterval(this.interval);
  }
};
</script>
