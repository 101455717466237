<script>
import FieldSelector from '../FieldSelector';
import { clickOutside } from 'vue-bootstrap';

export default {
  components: {
    FieldSelector
  },
  mixins: [clickOutside],
  props: {
    pairings: {
      required: true,
      type: Object
    },
    field: {
      required: true,
      type: Object
    },
    tab: {
      required: true,
      type: Object
    },
    selectedTab: {
      default: null,
      type: Object
    },
    sourceFields: {
      required: true,
      type: Object
    },
    labels: {
      required: true,
      type: Object
    },
    compatibilityMap: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      value: null
    };
  },
  computed: {
    richValue: {
      get() {
        if (this.value) {
          let value = this.value;

          this.selectableSourceFields.forEach(field => {
            const regex = new RegExp('{'+field.slug+'}', 'g');
            value = value.replace(regex, '{'+field.slug+': '+field.title+'}');
          });

          return value;
        }
        return null;
      },
      set(value) {
        const slugs = this.selectableSourceFields.map(field => '\{'+field.slug).join('|');
        const regex = new RegExp('('+slugs+'):.+?(\})', 'g');

        this.value = value.replace(regex, '$1$2');
      }
    },
    pairing() {
      const resource = this.field.resource.toLowerCase();
      const pairings = this.pairings[resource] || {};

      // Extract the pairing for the tab and field
      return ((pairings[this.tab.slug] || {})[this.field.slug] || {}).pairing;
    },
    selectableSourceFields() {
      const resource = this.field.resource;
      let sourceFields = this.sourceFields[resource] || [];

      sourceFields = sourceFields.filter(sourceField => {
        return this.compatibilityMap[this.field.type].includes(sourceField.type);
      });

      if (
        (resource === 'Contributors' && this.selectedTab.type === 'Contributors') ||
        (resource === 'Attachments' && this.selectedTab.type === 'Attachments')
      ) {
        return sourceFields.filter(field => field.tabId === this.selectedTab.id);
      }

      return sourceFields;
    },
    isOneToOnePairing() {
      return this.compatibilityMap[this.field.type].length === 1;
    }
  },
  watch: {
    value() {
      if (this.value === undefined) {
        return;
      }

      // Every time the value changes emit event so that field pairings can be updated
      this.$emit('input', this.tab, this.field, this.value);
    },
    pairing() {
      this.value = this.pairing;
    }
  },
  created() {
    this.value = this.pairing;
  },
  methods: {
    closeFieldSelector() {
      this.$refs.fieldSelector.close();
    },
    handleInput(event) {
      this.richValue = event.target.value;
    },
    selectField(field) {
      if (this.isOneToOnePairing) {
        this.richValue = '{'+field.slug+'}';
      } else {
        this.richValue = (this.value ? this.value : '') + '{'+field.slug+'}';
      }
    },
    clearSelection() {
      this.richValue = '';
    }
  }
};
</script>
