<template>
  <div class="alert-error sticky">
    <div class="icon">
      <i class="af-icons-md af-icons-md-alert-error"></i>
    </div>
    <div class="message">
      <p>{{ translations.formLimitReached }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    translations: {
      type: Object,
      required: true,
    },
  },
};
</script>
