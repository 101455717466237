<script>
import Account from './Account';

/**
 * The AccountSwitcher component.
 */
export default {
  components: {
    Account
  }
};
</script>
