<template>
  <div class="alert-error sticky">
    <div class="icon">
      <i class="af-icons-md af-icons-md-alert-error"></i>
    </div>
    <div class="message">
      <p v-if="failedMessage" v-text="failedMessage"></p>
      <ul v-if="errors && errors.length">
        <li v-for="error in errors" :key="error" v-text="error"></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    failedMessage: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
