$('body')
  .on('click', 'a[data-redirector]', function () {
    $(this).data('redirector-original', $(this).attr('href'));
    $(this).attr('href', $(this).data('redirector'));
    $(this).attr('target', '_blank');
  })
  .on('focusout', 'a[data-redirector]', function () {
    if (original = $(this).data('redirector-original')) {
      $(this).attr('href', original);
      $(this).attr('target', '');
    }
  });
