<template>
  <div>
    <CompletedMessage v-if="completed" :translations="translations" />
    <ErrorMessage v-else-if="failedMessage || errors.length" :failed-message="failedMessage" :errors="errors" />
    <InProgressMessage v-else :translations="translations" />
  </div>
</template>

<script>
import CompletedMessage from './CompletedMessage.vue';
import ErrorMessage from './ErrorMessage.vue';
import InProgressMessage from './InProgressMessage.vue';
import { on, off } from '@/Library/LocalEventBus';

const checkStatusInterval = 30000;
export default {
  components: {
    CompletedMessage,
    ErrorMessage,
    InProgressMessage,
  },
  props: {
    translations: {
      type: Object,
      required: true,
    },
    statusRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      completed: false,
      failedMessage: null,
      errors: [],
    };
  },
  created() {
    on('account.copy.completed', () => {
      this.completed = true;
    });

    on('account.copy.failed', ([message, errors]) => {
      this.failedMessage = message;
      this.errors = errors;
    });
  },
  mounted() {
    setTimeout(() => this.updateStatus(), checkStatusInterval);
  },
  beforeDestroy() {
    off('account.copy.completed');
    off('account.copy.failed');
  },
  methods: {
    updateStatus() {
      if (this.completed || this.failedMessage || this.errors.length) {
        return;
      }

      this.resetData();
      fetch(this.statusRoute)
        .then(response => response.json())
        .then(data => {
          if (this.isPending(data)) {
            setTimeout(() => this.updateStatus(), checkStatusInterval);
            return;
          }

          switch (data.status) {
            case 'success':
              this.completed = true;
              break;
            case 'failed':
              this.failedMessage = data.statusMessage;
              break;
            default:
              console.error(`Unknown status: ${data.status}`, data);
              break;
          }
        })
        .catch(error => console.error('Error:', error));
    },
    isPending(data) {
      return data.locked || data.status === 'pending';
    },
    resetData() {
      this.completed = false;
      this.failedMessage = null;
      this.errors = [];
    },
  }
};
</script>
