<template>
  <div>
    <div class="panel panel-bordered">
      <div class="panel-body">
        <h2>{{ translations.chooseSettings }}</h2>
        <div class="row">
          <div v-for="resource in resources" :key="resource.name" class="col-xs-12 col-sm-6 col-md-4">
            <ResourceCheckbox :resource="resource" :resource-states="resourceStates" @change="updateResourceState(resource.name)" />
          </div>
        </div>

        <overwriting-alert resource="account" :overwritten-notice="overwrittenResources" :translations="translations" :old-values="oldValues" />
      </div>
    </div>

    <div class="panel panel-bordered">
      <div class="panel-body">
        <h2>{{ translations.chooseSeason }}</h2>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-4">
            <div class="form-group">
              <label for="sourceSeason">{{ translations.sourceSeason }}</label>
              <select-field
                id="sourceSeason"
                name="sourceSeason"
                :items="toSelectArray(sourceSeasons)"
                :value="selectedSourceSeason"
                :required="true"
                @selected="selectSourceSeason"
              />
            </div>
            <p>{{ translations.destinationSeason }}</p>
            <radio-input id="new-season-strategy" :selected="seasonStrategy" name="seasonStrategy" :label="translations.copyToNewSeason" value="retain" @change="seasonStrategy = 'retain'" />
            <radio-input id="existing-season-strategy" :selected="seasonStrategy" name="seasonStrategy" :label="translations.copyToExistingSeason" value="replace" @change="seasonStrategy = 'replace'" />

            <div v-show="seasonStrategy === 'replace'" class="form-group indent-option">
              <select-field
                name="destinationSeason"
                :items="toSelectArray(destinationSeasons)"
                :value="selectedDestinationSeason"
                :required="true"
                @selected="selectDestinationSeason"
              />
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4">
            <p>{{ translations.seasonForms }}</p>
            <div v-for="form in selectedSeasonForms" :key="'form-' + form.slug">
              <FormCheckbox :form="form" :selected-source-forms="selectedSourceForms"  @change="toggleForm(form.slug)" />
              <template v-if="selectedSourceForms[form.slug]">
                <div v-for="formResource in formResources" :key="'form-' + form.id + '-resource-' + formResource.name">
                  <FormResourceCheckbox :form="form" :form-states="formStates" :form-resource="formResource" @change="updateFormState(formResource.name, form.slug)" />
                </div>
              </template>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4">
            <p>{{ translations.seasonSettings }}</p>
            <div v-for="seasonResource in seasonResources" :key="seasonResource.name">
              <ResourceCheckbox :resource="seasonResource" :resource-states="resourceStates" @change="updateResourceState(seasonResource.name)" />
            </div>
          </div>
        </div>

        <overwriting-alert resource="form" :overwritten-notice="overwrittenFormResources" :translations="translations" :old-values="oldValues" />
      </div>
    </div>

    <ChapterLimitReachedMessage v-if="chapterLimitReached && copingChapters" :translations="translations" />
    <FormLimitReachedMessage v-else-if="formLimitReached" :translations="translations" />
    <ConfirmCopyModal v-else :translations="translations" :account-list-route="accountListRoute" />
  </div>
</template>

<script>
import { Checkbox, SelectField, Modal } from 'vue-bootstrap';
import OverwritingAlert from '@/Modules/Accounts/Configuration/OverwritingAlert';
import RadioInput from "@/Components/Form/RadioInput.vue";
import ResourceCheckbox from "@/Modules/Accounts/Configuration/ResourceCheckbox.vue";
import FormResourceCheckbox from "@/Modules/Accounts/Configuration/FormResourceCheckbox.vue";
import FormCheckbox from "@/Modules/Accounts/Configuration/FormCheckbox.vue";
import FormLimitReachedMessage from "@/Modules/Accounts/Configuration/FormLimitReachedMessage.vue";
import ChapterLimitReachedMessage from "@/Modules/Accounts/Configuration/ChapterLimitReachedMessage.vue";
import ConfirmCopyModal from "@/Modules/Accounts/Configuration/ConfirmCopyModal.vue";

export default {
  components: {ConfirmCopyModal, ChapterLimitReachedMessage, FormLimitReachedMessage, FormCheckbox, FormResourceCheckbox, ResourceCheckbox, Checkbox, SelectField, Modal, RadioInput, OverwritingAlert},
  props: {
    resources: {
      type: Array,
      required: true,
    },
    formResources: {
      type: Array,
      required: true,
    },
    seasonResources: {
      type: Array,
      required: true,
    },
    sourceForms: {
      type: Array,
      required: true,
    },
    formDependencies: {
      type: Array,
      required: true,
    },
    sourceSeasons: {
      type: Array,
      required: true,
    },
    destinationSeasons: {
      type: Array,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    overwrittenResources: {
      type: String,
      required: true,
    },
    overwrittenFormResources: {
      type: String,
      required: true,
    },
    sourceSeasonChapterCount: {
      type: Object,
      required: true,
    },
    destinationRemainingChapterCount: {
      type: Number,
      default: null,
    },
    destinationChapterLimit: {
      type: Number,
      default: null,
    },
    destinationRemainingFormCount: {
      type: Number,
      default: null,
    },
    destinationFormLimit: {
      type: Number,
      default: null,
    },
    accountListRoute: {
      type: String,
      required: true,
    },
    oldValues: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    let seasonStrategy = 'retain';
    let selectedSourceSeason = this.sourceSeasons.length > 0 ? parseInt(this.sourceSeasons[0]['id']) : null;
    let selectedDestinationSeason = this.destinationSeasons.length > 0 ? parseInt(this.destinationSeasons[0]['id']) : null;

    if (Object.keys(this.oldValues).length > 0) {
      seasonStrategy = this.oldValues.seasonStrategy;
      selectedSourceSeason = this.oldValues.sourceSeason ? parseInt(this.oldValues.sourceSeason) : null;
      selectedDestinationSeason = this.oldValues.destinationSeason ? parseInt(this.oldValues.destinationSeason) : null;
    }

    return {
      seasonStrategy: seasonStrategy,
      resourceStates: {},
      formStates: {},
      selectedSourceForms: {},
      selectedDestinationSeason: selectedDestinationSeason,
      selectedSourceSeason: selectedSourceSeason,
      showConfirmModal: false,
    };
  },
  computed: {
    initialResourceStates() {
      return [...this.resources, ...this.seasonResources].reduce((states, resource) => {
        states[resource.name] = { disabled: resource.disabled };
        return states;
      }, {});
    },
    selectedSeasonForms() {
      return this.sourceForms.filter(form => form.seasonId === this.selectedSourceSeason);
    },
    chapterLimitReached() {
      if (this.destinationRemainingChapterCount === null) {
        return false;
      }

      if (this.seasonStrategy === 'retain') {
        return this.sourceSeasonChapterCount[this.selectedSourceSeason] > this.destinationChapterLimit;
      }

      return this.destinationRemainingChapterCount < this.sourceSeasonChapterCount[this.selectedSourceSeason];
    },
    copingChapters() {
      return this.resourceStates['chapters'].checked || false;
    },
    selectedFormCount() {
      return Object.values(this.selectedSourceForms).filter(selected => selected).length;
    },
    formLimitReached() {
      if (this.destinationRemainingFormCount === null) {
        return false;
      }

      if (this.seasonStrategy === 'retain') {
        return this.selectedFormCount > this.destinationFormLimit;
      }

      return this.destinationRemainingFormCount < this.selectedFormCount;
    },
  },
  watch: {
    selectedSourceForms: {
      deep: true,
      handler(newVal) {
        const atLeastOneFormIsSelected = Object.values(newVal).some((selected) => selected);

        if (atLeastOneFormIsSelected) {
          this.formDependencies.forEach((dependency) => this.checkAndDisableResourceState(dependency));
        } else {
          this.formDependencies.forEach((dependency) => this.enableResourceState(dependency));
        }
      },
    },
  },
  created() {
    this.initiateSelectedForms();
    this.initiateResourceStates();
    this.initiateFormStates();
  },
  methods: {
    initiateSelectedForms() {
      let seasonId = this.selectedSourceSeason || 0;

      this.selectedSourceForms = this.sourceForms.reduce((forms, form) => {
        seasonId = seasonId || form.seasonId;
        forms[form.slug] = !!(this.oldValues?.forms?.[form.id] || form.seasonId === seasonId);

        return forms;
      }, {});
    },
    initiateResourceStates() {
      [...this.resources, ...this.seasonResources].forEach((resource) => {
        this.$set(this.resourceStates, resource.name, {
          ...resource,
          checked: this.oldValues?.resources?.includes(resource.name) ?? !resource.disabled
        });
      });
    },
    initiateFormStates() {
      this.sourceForms.forEach((form) => {
        this.$set(this.formStates, form.slug, this.formResources.map(resource => ({
          ...resource,
          checked: this.oldValues?.forms?.[form.id]?.includes(resource.name) ?? true,
        })));
      });
    },
    selectSourceSeason(name, value) {
      this.selectedSourceSeason = parseInt(value);
    },
    selectDestinationSeason(name, value) {
      this.selectedDestinationSeason = parseInt(value);
    },
    toggleResourceState(resourceName, forceCheck = false) {
      const initialResource = this.initialResourceStates[resourceName];
      const resource = this.resourceStates[resourceName];

      if (!initialResource || initialResource.disabled || resource.disabled) {
        return;
      }

      this.$set(resource, 'checked', forceCheck ? true : !resource.checked);
    },
    checkAndDisableResourceState(resourceName) {
      const resource = this.resourceStates[resourceName];
      this.$set(resource, 'checked', true);
      this.$set(resource, 'disabled', true);
    },
    enableResourceState(resourceName) {
      const initialResource = this.initialResourceStates[resourceName];

      if (!initialResource || initialResource.disabled) {
        return;
      }

      const resource = this.resourceStates[resourceName];
      this.$set(resource, 'disabled', false);
    },
    updateResourceState(resourceName, forceCheck = false) {
      this.toggleResourceState(resourceName, forceCheck);
      this.updateParentResourceDependencies(resourceName);
      this.updateChildResourceDependencies(resourceName);
    },
    updateFormState(resourceName, formSlug) {
      const resource = this.formStates[formSlug].find(resource => resource.name === resourceName);

      if (!resource || resource.disabled) {
        return;
      }

      this.$set(resource, 'checked', !resource.checked);
      this.updateParentFormDependencies(resourceName, formSlug);
      this.updateChildFormDependencies(resourceName, formSlug);
    },
    updateParentFormDependencies(resourceName, formSlug) {
      const resource = this.formStates[formSlug].find(resource => resource.name === resourceName);
      const isChecked = resource.checked;
      const dependencyNames = resource.dependencies;

      dependencyNames.forEach((dependencyName) => {
        const dependency = this.formStates[formSlug].find(resource => resource.name === dependencyName);

        if (!dependency) {
          if (isChecked) {
            this.updateResourceState(dependencyName, true);
          }

          return;
        }

        if (isChecked) {
          this.$set(dependency, 'checked', true);
        } else if (!dependency.required) {
          this.$set(dependency, 'disabled', false);
        }

        this.updateParentFormDependencies(dependencyName, formSlug);
      });
    },
    updateChildFormDependencies(resourceName, formSlug) {
      const resource = this.formStates[formSlug].find(resource => resource.name === resourceName);
      const isChecked = resource.checked;

      if (isChecked) {
        return;
      }

      const dependencies = this.formStates[formSlug].filter((resource) => resource.dependencies.includes(resourceName));

      dependencies.forEach((dependency) => {
        this.$set(dependency, 'checked', false);
        this.updateChildFormDependencies(dependency.name, formSlug);
      });
    },
    updateChildResourceDependencies(resourceName) {
      const isChecked = this.resourceStates[resourceName].checked;

      if (isChecked) {
        return;
      }

      this.uncheckChildResources(resourceName);
      this.uncheckChildFormResources(resourceName);
    },
    uncheckChildResources(resourceName) {
      Object.keys(this.resourceStates)
        .filter((dependencyName) => this.resourceStates[dependencyName].dependencies.includes(resourceName))
        .forEach((dependentName) => {
          this.$set(this.resourceStates[dependentName], 'checked', false);
          this.updateChildResourceDependencies(dependentName);
        });
    },
    uncheckChildFormResources(resourceName) {
      Object.values(this.formStates).forEach((formResources) => {
        formResources
          .filter((formResource) => formResource.dependencies.includes(resourceName))
          .forEach((formResource) => {
            if (formResource.disabled) {
              return;
            }

            formResource.checked = false;
          });
      });
    },
    updateParentResourceDependencies(resourceName) {
      const isChecked = this.resourceStates[resourceName].checked;

      const dependencies = Object.keys(this.resourceStates)
        .filter((dependentName) => !this.initialResourceStates[dependentName].disabled)
        .filter((dependentName) => this.resourceStates[resourceName].dependencies.includes(dependentName));

      if (isChecked) {
        dependencies.forEach((dependentName) => {
          this.$set(this.resourceStates[dependentName], 'checked', true);
          this.$set(this.resourceStates[dependentName], 'disabled', true);
          this.updateParentResourceDependencies(dependentName);
        });
      } else {
        dependencies.forEach((dependentName) => {
          this.$set(this.resourceStates[dependentName], 'disabled', false);
          this.updateParentResourceDependencies(dependentName);
        });
      }
    },
    toggleForm(formSlug) {
      this.selectedSourceForms[formSlug] = !this.selectedSourceForms[formSlug];

      this.formStates[formSlug].forEach((resource) => {
        this.updateParentFormDependencies(resource.name, formSlug);
        this.updateChildFormDependencies(resource.name, formSlug);
      });
    },
    toSelectArray(object) {
      return Object.entries(object).map(([key, value]) => ({ id: value['id'], name: value['name'] }));
    },
  },
};
</script>

