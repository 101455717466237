<script>
import ColourJuggler from '../../Components/ColourJuggler';

export default {
  extends: ColourJuggler,
  methods: {
    startChallenge() {
      document.getElementById("start-challenge-form").submit();
    }
  }
};
</script>
