<template>
  <div>
    <modal
      v-model="showConfirmModal"
      :header="false"
      :confirm-button-label="translations.confirmButton"
      :close-button-label="translations.closeButton"
      @closed="cancel"
      @confirmed="confirmedCopy"
    >
      {{ translations.confirmMessage }}
    </modal>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <button type="button" class="btn btn-primary btn-lg" @click="showConfirmation">
          {{ translations.copyButton }}
        </button>
        <a :href="accountListRoute" class="btn btn-tertiary btn-lg">
          {{ translations.closeButton }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'vue-bootstrap';
export default {
  components: {Modal},
  props: {
    translations: {
      type: Object,
      required: true,
    },
    accountListRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showConfirmModal: false,
    };
  },
  methods: {
    showConfirmation() {
      this.showConfirmModal = true;
    },
    cancel() {
      this.showConfirmModal = false;
    },
    confirmedCopy() {
      document.getElementById('copy-form').submit();
    },
  }
};
</script>


