<script>
export default {
  data() {
    return {
      active: false,
      completed: false,
      initials: false,
      gravatar: false,
      fullName: false,
    };
  },
  props: {
    globalId: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  mounted: function() {
    this.activate();
  },
  methods: {
    activate() {
      this.active = true;
    }
  },
  watch: {
    async active() {
      const { data } = await this.$http.get(this.endpoint);

      this.initials = data.initials;
      this.gravatar = data.gravatar;
      this.fullName = data.fullName;

      this.completed = true;
    }
  },
};
</script>
