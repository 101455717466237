import Vue from 'vue';

import ButtonDelete from './Buttons/ButtonDelete';
import DataTable from './DataTable';
import LanguageSelection from './LanguageSelection';
import Paginator from './Paginator';

/**
 * Register global components.
 */
Vue.component('button-delete', ButtonDelete);
Vue.component('data-table', DataTable);
Vue.component('language-selection', LanguageSelection);
Vue.component('paginator', Paginator);
