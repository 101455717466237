<script>
export default {
  props: {
    limit: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      perPage: this.limit
    };
  },
  methods: {
    changePerPage() {
      var fullUrl = window.location.href;
      var data = this.updateURLParameter(fullUrl, 'per_page', this.perPage, 'page');

      $.pjax({url: this.url(fullUrl), data: data, method: 'GET'});
    },
    url(href) {
      return href.split('?')[0];
    },
    updateURLParameter(url, param, paramVal, ignore) {
      var newAdditionalURL = "";
      var tempArray = url.split("?");
      var additionalURL = tempArray[1];
      var currentParam = "";
      var temp = "";

      if (additionalURL) {
        tempArray = additionalURL.split("&");

        for (var i = 0; i < tempArray.length; i++) {
          currentParam = tempArray[i].split('=')[0];

          if (currentParam == param || currentParam == ignore) {
            continue;
          }

          newAdditionalURL += temp + tempArray[i];
          temp = "&";
        }
      }

      var rows_txt = temp + "" + param + "=" + paramVal;

      return newAdditionalURL + rows_txt;
    }
  }
};
</script>
