<template>
  <div class="alert-success sticky">
    <div class="icon">
      <i class="af-icons-md af-icons-md-alert-tick"></i>
    </div>
    <div class="message">
      <p v-html="translations.completedMessage"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    translations: {
      type: Object,
      required: true,
    },
  },
};
</script>
