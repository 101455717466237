<template>
  <div v-if="hasFields">
    <h2>{{ tab.name }}</h2>
    <div v-if="contributorTabSelectable" class="form-group">
      <select-field
        :items="sourceContributorTabs"
        :value="selectedTab.id"
        @selected="onTabSelected"
      >
      </select-field>
    </div>
    <div v-if="attachmentTabSelectable" class="form-group">
      <select-field
        :items="sourceAttachmentTabs"
        :value="selectedTab.id"
        @selected="onTabSelected"
      >
      </select-field>
    </div>
    <component
      :is="component(field.type)"
      v-for="field in tab.fields"
      :key="field.id"
      :pairings="pairings"
      :field="field"
      :tab="tab"
      :selected-tab="selectedTab"
      :source-fields="sourceFields"
      :labels="labels"
      :compatibility-map="compatibilityMap"
      @input="onInput"
    >
    </component>
  </div>
</template>

<script>
import CheckboxPairing from './Pairings/CheckboxPairing';
import InputPairing from './Pairings/InputPairing';
import TextareaPairing from './Pairings/TextareaPairing';
import TitlePairing from './Pairings/TitlePairing';
import { SelectField } from 'vue-bootstrap';

/**
 * The PairFields component.
 */
export default {
  components: {
    CheckboxPairing,
    InputPairing,
    TextareaPairing,
    TitlePairing,
    SelectField
  },
  props: {
    tab: {
      required: true,
      type: Object
    },
    sourceTabs: {
      required: true,
      type: Array
    },
    destinationTabs: {
      required: true,
      type: Array
    },
    pairings: {
      required: true,
      type: Object
    },
    sourceFields: {
      required: true,
      type: Object
    },
    labels: {
      required: true,
      type: Object
    },
    compatibilityMap: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      selectedTab: null
    };
  },
  computed: {
    hasFields() {
      return this.tab.fields && this.tab.fields.length > 0;
    },
    sourceContributorTabs() {
      return this.sourceTabs.filter(tab => tab.type === 'Contributors');
    },
    destinationContributorTabs() {
      return this.destinationTabs.filter(tab => tab.type === 'Contributors');
    },
    isContributorTab() {
      return !!this.destinationContributorTabs.find(contributorTab => contributorTab.slug === this.tab.slug);
    },
    contributorTabSelectable() {
      return this.isContributorTab && this.sourceContributorTabs.length > 1;
    },
    sourceAttachmentTabs() {
      return this.sourceTabs.filter(tab => tab.type === 'Attachments');
    },
    destinationAttachmentTabs() {
      return this.destinationTabs.filter(tab => tab.type === 'Attachments');
    },
    isAttachmentTab() {
      return !!this.destinationAttachmentTabs.find(attachmentTab => attachmentTab.slug === this.tab.slug);
    },
    attachmentTabSelectable() {
      return this.isAttachmentTab && this.sourceAttachmentTabs.length > 1;
    },
  },
  created() {
    if (this.isContributorTab && this.sourceContributorTabs.length) {
      this.preselectTab(this.sourceContributorTabs);
    }

    if (this.isAttachmentTab && this.sourceAttachmentTabs.length) {
      this.preselectTab(this.sourceAttachmentTabs);
    }

    if (this.isContributorTab || this.isAttachmentTab) {
      this.$emit('tabs-paired', this.tab, this.selectedTab);
    }
  },
  methods: {
    component(fieldType) {
      switch (fieldType) {
        case 'textarea':
          return 'TextareaPairing';
        case 'checkbox':
          return 'CheckboxPairing';
        case 'title':
          return 'TitlePairing';
        default:
          return 'InputPairing';
      }
    },
    preselectTab(tabs) {
      const tabSlug = (this.pairings['tabs'] || {})[this.tab.slug];
      const tab = tabs.find(tab => tab.slug === tabSlug);

      // Preselect tab
      this.selectedTab = tab ? tab : tabs[0];
    },
    onTabSelected(name, id) {
      this.selectedTab = this.sourceTabs.find(tab => tab.id === id);

      this.$emit('tabs-paired', this.tab, this.selectedTab);
    },
    onInput(tab, field, value) {
      this.$emit('fields-paired', tab, field, value);
    }
  },
};
</script>
