<script>
import ColourJuggler from '../../Components/ColourJuggler';

/**
 * The PasswordReset component.
 */
export default {
  extends: ColourJuggler
};
</script>
