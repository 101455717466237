<template>
  <div class="form-group">
    <label>{{ field.title }}</label>
    <div v-on-click-outside="closeFieldSelector" class="field-pairing">
      <input :value="richValue" type="text" class="form-control" :disabled="isOneToOnePairing" @input="handleInput">
      <field-selector
        ref="fieldSelector"
        :source-fields="selectableSourceFields"
        :labels="labels"
        :show-clear-control="richValue && isOneToOnePairing"
        @selected="selectField"
        @cleared="clearSelection"
      >
      </field-selector>
    </div>
  </div>
</template>

<script>
import FieldPairing from './FieldPairing';

export default {
  extends: FieldPairing
};
</script>
