<script>
import { Popover } from 'vue-bootstrap';
import ColourJuggler from '../../Components/ColourJuggler';

/**
 * The Login component.
 */
export default {
  components: {
    Popover
  },
  extends: ColourJuggler,
  data() {
    return {
      reminder: false
    };
  },
  methods: {
    toggle() {
      if (this.reminder) {
        this.$refs.reminderHelp.close();
      } else {
        this.$refs.loginHelp.close();
      }

      this.$nextTick(() => {
        this.reminder = !this.reminder;
      });
    }
  }
};
</script>
