<template>
  <component :is="wrapper">
    <a :href="url" :class="buttonClass" @click.prevent="confirm">
      {{ buttonText }}
    </a>
    <modal
      v-model="showModal"
      :title="modalTitle"
      :confirm-button-label="modalConfirm"
      :close-button-label="modalClose"
      @closed="cancel"
      @confirmed="confirmedDelete"
    >
      <i slot="close" class="af-icons af-icons-cross"></i>
      {{ modalText }}
    </modal>
  </component>
</template>

<script>
import { Modal } from 'vue-bootstrap';

export default {
  components: {
    Modal
  },
  props: {
    url: {
      type: String,
      required: true
    },
    wrapper: {
      type: String,
      default: 'span'
    },
    buttonClass: {
      type: String,
      default: 'btn btn-danger'
    },
    buttonText: {
      type: String,
      required: true
    },
    modalText: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    modalConfirm: {
      type: String,
      default: 'Confirm'
    },
    modalClose: {
      type: String,
      default: 'Close'
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    confirmedDelete() {
      this.$http.delete(this.url).then(response => {
        window.location.href = response.data;
      }, response => {
        console.log(response);
      });
    },
    confirm() {
      this.showModal = true;
    },
    cancel() {
      this.showModal = false;
      this.$emit('canceled');
    }
  }
};
</script>
