let nextId = 0;

const listeners = {};

/**
 * Registers an event listener.
 * @param event - event name
 * @param callback - callback being registered
 * @returns id of the listener
 */
const on = (event, callback) => {
  if (!listeners[event]) {
    listeners[event] = [];
  }

  const id = nextId++;
  listeners[event].push({id, callback});

  return id;
};

/**
 * Unregisters an event listener based on event name and listener id.
 * If listener id is not provided, all listeners for the given event are unregistered.
 * @param event - name of event to unregister
 * @param id - id of listener to unregister
 */
const off = (event, id) => {
  const eventListeners = listeners[event];

  if (id !== undefined) {
    listeners[event] = eventListeners.filter((listener) => listener.id !== id);
  } else {
    delete listeners[event];
  }
};

/**
 * Emits an event with payload.
 * @param event - name of event to emit
 * @param payload - payload to emit
 */
const emit = (event, payload) => {
  const eventListeners = listeners[event];

  if (eventListeners !== undefined) {
    eventListeners.forEach((listener) => listener.callback(payload, listener.id));
  }
};

export { on, off, emit };
