<template>
  <div :class="classes">
    <label v-if="label" :for="getId">{{ label }}</label>
    <input :id="getId" :name="name" type="text" :value="value" :disabled="disabled" class="form-control">
  </div>
</template>

<script>
import BaseInput from './BaseInput';

export default {
  extends: BaseInput,
};
</script>
