<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Boolean],
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: 'form-group',
    },
  },
  computed: {
    getId() {
      return this.id || this.name;
    },
  },
};
</script>
