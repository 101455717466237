<template>
  <div class="form-group">
    <checkbox
      :key="resource.name"
      name="resources[]"
      :value="resource.name"
      :selected="resourceStates[resource.name].checked"
      :disabled="resourceStates[resource.name].disabled"
      @change="$emit('change')"
    >
      <template slot="label">
        {{ resource.formattedLabel }}
      </template>
    </checkbox>
    <input
      v-if="resourceStates[resource.name].checked"
      type="hidden"
      name="resources[]"
      :value="resource.name"
    />
  </div>
</template>

<script>
import { Checkbox } from 'vue-bootstrap';
export default {
  components: {Checkbox},
  props: {
    resource: {
      type: Object,
      required: true,
    },
    resourceStates: {
      type: Object,
      required: true,
    },
  },
};
</script>
