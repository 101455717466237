<template>
  <div>
    <tab
      v-for="tab in destinationTabs"
      :key="tab.id"
      :tab="tab"
      :source-tabs="sourceTabs"
      :destination-tabs="destinationTabs"
      :pairings="pairings"
      :source-fields="sourceFields"
      :labels="labels"
      :compatibility-map="compatibilityMap"
      @fields-paired="onFieldsPaired"
      @tabs-paired="onTabsPaired"
    >
    </tab>
    <input type="hidden" name="pairings" :value="jsonPairings">
  </div>
</template>

<script>
import Tab from './Tab';

/**
 * The PairFields component.
 */
export default {
  components: {
    Tab
  },
  props: {
    sourceTabs: {
      required: true,
      type: Array
    },
    destinationTabs: {
      required: true,
      type: Array
    },
    sourceFields: {
      required: true,
      type: Object
    },
    labels: {
      required: true,
      type: Object
    },
    initialPairings: {
      required: true,
      type: Object
    },
    compatibilityMap: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      pairings: JSON.parse(JSON.stringify(this.initialPairings))
    };
  },
  computed: {
    jsonPairings() {
      return JSON.stringify(this.pairings);
    }
  },
  methods: {
    pairTitle(title) {
      if (title) {
        this.$set(this.pairings, 'title', title);
      } else {
        this.$delete(this.pairings, 'title');
      }
    },
    onFieldsPaired(tab, field, value) {
      const resource = field.resource.toLowerCase();

      if (field.slug === 'title') {
        this.pairTitle(value);

        return;
      }

      if (!this.pairings[resource]) {
        this.$set(this.pairings, resource, {});
      }

      if (!this.pairings[resource][tab.slug]) {
        this.$set(this.pairings[resource], tab.slug, {});
      }

      if (value) {
        this.$set(this.pairings[resource][tab.slug], field.slug, { type: field.type, pairing: value });
      } else {
        this.$delete(this.pairings[resource][tab.slug], field.slug);
      }
    },
    onTabsPaired(destinationTab, sourceTab) {
      if (!this.pairings['tabs']) {
        this.$set(this.pairings, 'tabs', {});
      }

      if (this.pairings['tabs'][destinationTab.slug] === sourceTab.slug) {
        return;
      }

      this.$set(this.pairings['tabs'], destinationTab.slug, sourceTab.slug);

      // The tab pairing has changed, clear the field pairings
      ['entries', 'contributors', 'attachments'].forEach(resource => {
        if ((this.pairings[resource] || {})[destinationTab.slug]) {
          this.$delete(this.pairings[resource], destinationTab.slug);
        }
      });
    }
  }
};
</script>
