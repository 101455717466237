<script>
import LanguageDropdown from './LanguageDropdown';

/**
 * The AppFooter component.
 */
export default {
  components: {
    LanguageDropdown
  }
};
</script>
