<script>
import ColourJuggler from './ColourJuggler';
import { Dropdown } from 'vue-bootstrap';
import Gravatar from "./Gravatar";

/**
 * The AppHeader component.
 */
export default {
  components: {
    Dropdown,
    Gravatar
  },
  extends: ColourJuggler
};
</script>
