var toastr = require('toastr');

toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": true,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300000",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

module.exports = {

  notify: function (type, message) {
    var types = ['info', 'warning', 'success', 'error'];
    type = types.indexOf(type) !== -1 ? type : 'info';

    toastr[type](message);
  },

  info: function (message) {
    toastr.info(message);
  },

  warning: function (message, modal = false) {
    if (modal) {
      toastr.options.timeOut = 0;
      toastr.options.extendedTimeOut = 0;
      toastr.options.closeButton = true;
      toastr.options.preventDuplicates = true;
      toastr.options.positionClass = 'toast-bottom-full-width';
    }
    toastr.warning(message);
  },

  success: function (message) {
    toastr.success(message);
  },

  error: function (message) {
    toastr.error(message);
  },

  response: function (response, error) {
    if (response && response.hasOwnProperty('error')) {
      toastr.error(response.error);
    } else if (response && response.hasOwnProperty('warning')) {
      toastr.warning(response.warning);
    } else if (response && response.hasOwnProperty('info')) {
      toastr.info(response.info);
    } else if (response && response.hasOwnProperty('success')) {
      toastr.success(response.success);
    } else {
      toastr.error(error);
    }
  }
};
