<template>
  <div>
    <a v-if="showClearControl" class="field-selector-control field-selector-control-clear" @click.prevent="clear">
      <span class="af-icons af-icons-cross"></span>
    </a>
    <a v-else class="field-selector-control field-selector-control-toggle" @click.prevent="show = !show">
      <span class="af-icons af-icons-fill"></span>
    </a>
    <div v-if="show" class="field-selector">
      <input v-model="query" type="text" :placeholder="labels.fieldSelectorPlaceholder">
      <div class="list scroll-vertically shadows">
        <ul>
          <template v-if="visibleFields.length">
            <li v-for="field in visibleFields" :key="field.id">
              <a class="field-tag" :title="field.title" @click.prevent="select(field)">
                {{ field.title }}
              </a>
            </li>
          </template>
          <li v-else>
            <span class="text-muted">{{ labels.fieldSelectorEmpty }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The FieldSelector component.
 */
export default {
  props: {
    sourceFields: {
      required: true,
      type: Array
    },
    labels: {
      required: true,
      type: Object
    },
    showClearControl: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      show: false,
      query: ''
    };
  },
  computed: {
    visibleFields() {
      return this.sourceFields.filter(field => {
        return field.title.toLowerCase().indexOf(this.query.toLowerCase()) !== -1;
      });
    }
  },
  methods: {
    close() {
      this.show = false;
    },
    clear() {
      this.close();
      this.$emit('cleared');
    },
    select(field) {
      this.close();
      this.$emit('selected', field);
    }
  }
};
</script>
