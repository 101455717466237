<script>
/**
 * The DataTable component.
 *
 * A simple wrapper around a table that provides functionality to check/uncheck all rows.
 */
export default {
  props: {
    rowCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      checkedRows: {}
    };
  },
  computed: {
    allChecked() {
      return Object.values(this.checkedRows).filter(checked => checked === true).length === this.rowCount;
    }
  },
  created() {
    for (let i = 0; i < this.rowCount; i++) {
      this.$set(this.checkedRows, this.rowId(i), false);
    }
  },
  methods: {
    rowId(index) {
      return 'row-'+index;
    },
    checkAll() {
      const checked = this.allChecked ? false : true;

      for (let i = 0; i < this.rowCount; i++) {
        this.$set(this.checkedRows, this.rowId(i), checked);
      }
    }
  },
};
</script>
