<script>
/**
 * The LanguageSelection component.
 */
export default {
  props: {
    preferredLanguage: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      selectedLanguage: null
    };
  },
  created() {
    this.selectedLanguage = this.preferredLanguage;
  },
  methods: {
    reload() {
      window.location = '/?language=' + this.selectedLanguage;
    }
  },
};
</script>
