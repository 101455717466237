// Polyfills
import './polyfills.js';

import Vue from 'vue';

// Global components
import './Library/clarify';
import './Components';
import './Library/redirector.js';
import './Library/pusher';

// AppHeader and AppFooter component
import AppHeader from './Components/AppHeader';
import AppFooter from './Components/AppFooter';

var header = document.getElementsByClassName('app-header');

if (header.length) {
  new Vue({
    el: header[0],
    components: { AppHeader }
  });
}

var footer = document.getElementsByClassName('app-footer');

if (footer.length) {
  new Vue({
    el: footer[0],
    components: { AppFooter }
  });
}

// Module-specific "route" components
import modules from './Modules';

// Axios setup
import axios from 'axios';
Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.headers.common['X-CSRF-TOKEN'] = App.csrfToken;

var vueInstance = null;
var vueState = {};

// The long timeout is to ensure that we don't get weird cancellation effects for
// semi-long requests (a few hundred ms). Also helps with slow connections.
// jquery-pjax responses will come up "cancelled" if they time out.
var timeout = 20000;
var pjaxContainer = '#pjaxContainer';
var loader = $('#loader');
var body = $('body');
var fatalError = false;
var cancelled = false;

$.pjax.defaults = {};
$.pjax.defaults.timeout = timeout;
$.pjax.defaults.container = pjaxContainer;
$.pjax.defaults.push = true;
$.pjax.defaults.maxCacheLength = 0;

var documentEl = $(document);

documentEl.on('ready pjax:end', function() {
  if (vueInstance) {
    vueInstance.$destroy();
  }

  vueInstance = new Vue({
    el: pjaxContainer,
    components: modules,
    data: { state: vueState }
  });
});

documentEl.pjax('.content a:not(.ignore)', pjaxContainer);

// Just in case the load time is really fast, we don't bother showing the loader.
documentEl.on('pjax:send', function() {
  loader.delay(150).show().fadeTo(250, 0.8);
});

// Whenever the loading is complete, we then want to hide the loading animation
documentEl.on('pjax:end', function() {
  if (fatalError) {
    fatalError = false;
    return;
  }
  // Hide loader without delaying and reset flag
  else if (cancelled) {
    loader.hide();
    cancelled = false;
    return;
  }

  loader.fadeTo(250, 0, function() { $(this).hide(); });

  // Send user to the top of the page.
  $('html, body').animate({ scrollTop: 0 }, 250);
});

// Handle the page title update
documentEl.on('pjax:end', function() {
  var newTitle = $('.content').find('div[data-title]').first();

  if (newTitle) {
    document.title = newTitle.text();
  }
});
