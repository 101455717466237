<script>
import { Dropdown } from 'vue-bootstrap';

/**
 * The LanguageDropdown component.
 */
export default {
  components: {
    Dropdown
  }
};
</script>
