<template>
  <div class="form-group indent-option">
    <checkbox
      :key="'form-' + form.slug + '-resource-' + formResource.name"
      :name="'forms[' + form.id + '][]'"
      :value="formResource.name"
      :selected="formStates[form.slug].find(resource => resource.name === formResource.name).checked"
      :disabled="formStates[form.slug].find(resource => resource.name === formResource.name).disabled"
      :required="formResource.required"
      @change="$emit('change')"
    >
      <template slot="label">
        {{ formResource.formattedLabel }}
      </template>
    </checkbox>
    <input
      v-if="formStates[form.slug].find(resource => resource.name === formResource.name).checked"
      type="hidden"
      :name="'forms[' + form.id + '][]'"
      :value="formResource.name"
    />
  </div>
</template>

<script>
import { Checkbox } from 'vue-bootstrap';
export default {
  components: {Checkbox},
  props: {
    formResource: {
      type: Object,
      required: true,
    },
    formStates: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
};
</script>



