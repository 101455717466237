<script>
import Vue from 'vue';
import InputPairing from './InputPairing';

/**
 * The TitlePairing component.
 */
export default {
  extends: InputPairing,
  computed: {
    pairing() {
      return this.pairings['title'];
    }
  }
};
</script>
