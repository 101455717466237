import Challenge from './Auth/Challenge';
import Login from './Auth/Login';
import PasswordReset from './Auth/PasswordReset';
import AccountSwitcher from './Accounts/AccountSwitcher';
import AccountConfiguration from './Accounts/Configuration/AccountConfiguration';
import CopyMessage from './Accounts/Configuration/CopyMessage';
import CopyEntries from './Entries/CopyEntries';
import ColourJuggler from '@/Components/ColourJuggler';

export default {
  Challenge,
  Login,
  ColourJuggler,
  PasswordReset,
  AccountSwitcher,
  AccountConfiguration,
  CopyMessage,
  CopyEntries
};
