<script>
import CategorySelector from './CategorySelector';
import PairFields from './PairFields';
import ListFieldPairings from './ListFieldPairings';

/**
 * The CopyEntries component.
 */
export default {
  components: {
    CategorySelector,
    PairFields,
    ListFieldPairings
  },
  props: {
    currentSourceSeason: {
      default: null,
      type: [String, Number]
    },
    currentDestinationSeason: {
      default: null,
      type: [String, Number]
    }
  },
  data() {
    return {
      sourceSeason: null,
      destinationSeason: null
    };
  },
  created() {
    this.sourceSeason = this.currentSourceSeason;
    this.destinationSeason = this.currentDestinationSeason;
  }
};
</script>
