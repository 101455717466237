<script>
import { Dropdown } from 'vue-bootstrap';

/**
 * The Account component.
 */
export default {
  components: {
    Dropdown
  },
  data() {
    return {
      canCopyEntries: false,
      canCopyConfiguration: false,
      loading: false,
      loaded: false,
      featureRoles: [],
      screenshotLoaded: false
    };
  },
  props: {
    globalId: {
      type: String,
      required: true,
    },
  },
  methods: {
    activate() {
      this.loading = true;
    }
  },
  watch: {
    async loading() {
      const { data } = await this.$http.get('account/' + this.globalId + '/permissions');

      this.canCopyEntries = data.canCopyEntries;
      this.canCopyConfiguration = data.canCopyConfiguration;
      this.featureRoles = data.featureRoles;
      this.loaded = true;
    }
  },
};
</script>

<style scoped>
  .ghost-link {
    background-color: #efefef;
    height: 15px;
    margin: 10px 18px;
    border-radius: 4px;
  }
  .loading {
    padding: 5px 18px;
  }
</style>
