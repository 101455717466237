<template>
  <div class="form-group">
    <checkbox
      :key="'form-' + form.slug"
      :name="'forms[' + form.id + ']'"
      :value="form.id"
      :selected="selectedSourceForms[form.slug]"
      @change="$emit('change')"
    >
      <template slot="label">
        {{ form.name }}
      </template>
    </checkbox>
  </div>
</template>

<script>
import { Checkbox } from 'vue-bootstrap';
export default {
  components: {Checkbox},
  props: {
    selectedSourceForms: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
};
</script>



