<template>
  <select-field :id="id" ref="select" :disabled="loading" :name="name" :empty-option="true" :items="categories" />
</template>

<script>
import Vue from 'vue';
import { SelectField } from 'vue-bootstrap';

/**
 * The CategorySelector component.
 *
 * Loads categories asynchronously based on the selected season.
 */
export default {
  components: {
    SelectField
  },
  props: {
    name: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: String
    },
    account: {
      required: true,
      type: [String]
    },
    season: {
      required: true,
      type: [Number, String]
    },
    category: {
      required: true,
      type: [Number, String]
    }
  },
  data() {
    return {
      categories: [],
      loading: false
    };
  },
  watch: {
    season(season) {
      this.fetchCategories(season);
    }
  },
  created() {
    this.fetchCategories(this.season);
  },
  methods: {
    fetchCategories(season) {
      if (!season) {
        this.categories = [];

        return;
      }

      this.loading = true;

      this.$http.get('/category/season?account=' + this.account + '&season=' + this.season).then(response => {
        this.categories = response.data;

        this.loading = false;

        if (this.category) {
          Vue.nextTick(() => {
            this.$refs.select.select(this.category);
          });
        }
      }, response => {
        console.log('Failed to fetch categories for season #' + season);
      });
    }
  },
};
</script>
