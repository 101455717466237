<template>
  <div class="alert-warning sticky">
    <div class="icon">
      <i class="af-icons-md af-icons-md-alert-warning"></i>
    </div>
    <div class="message">
      <p>{{ overwrittenNotice }}</p>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <p>{{ translations.forAllOtherSettings }}</p>
          <radio-input :id="resource + '-retain-strategy'" :name="resource + 'Strategy'" :label="translations.retainStrategy" value="retain" :selected="selectedStrategy" />
          <text-input :label="translations.copyTermLabel" :value="translations.copyTermValue" :name="resource + 'CopyTerm'" classes="indent-option" />
<!--          <radio-input :id="resource + '-replace-strategy'" :name="resource + 'Strategy'" :label="translations.replaceStrategy" value="replace" :selected="selectedStrategy" />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioInput from '@/Components/Form/RadioInput';
import TextInput from '@/Components/Form/TextInput';

export default {
  components: {TextInput, RadioInput},
  props: {
    overwrittenNotice: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    oldValues: {
      type: [Array, Object],
      required: true,
    },
  },
  computed: {
    selectedStrategy() {
      return this.oldValues && this.oldValues[this.resource + 'Strategy'] ? this.oldValues[this.resource + 'Strategy'] : 'retain';
    },
  },
};
</script>
